<template>
  <div>
    <b-row v-if="orderData" class="row-eq-height">
      <b-col class="mb-1" cols="6">
        <b-card title="Order" class="h-100">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ orderData.id }}</td>
              </tr>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-right">{{ orderData.code }}</td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge variant="primary">
                    {{ orderStatus }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th class="text-left">Type</th>
                <td class="text-right">{{ orderData.type }}</td>
              </tr>
              <tr>
                <th class="text-left">note</th>
                <td class="text-right">{{ orderData.note }}</td>
              </tr>
              <tr>
                <th class="text-left">Date</th>
                <td class="text-right">{{ orderData.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col cols="6" class="mb-1">
        <b-card title="Order Total" class="h-100">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Tax</th>
                <td class="text-right">{{ orderData.tax }}</td>
              </tr>
              <tr>
                <th class="text-left">Fees</th>
                <td class="text-right">{{ orderData.fees }}</td>
              </tr>
              <tr>
                <th class="text-left">Shipping Price</th>
                <td class="text-right">{{ orderData.shipping_price }}</td>
              </tr>
              <tr>
                <th class="text-left">Discounte</th>
                <td class="text-right">
                  {{
                    orderData.coupon != null ? orderData.coupon.percentage : "-"
                  }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Total</th>
                <th class="text-right">{{ orderData.total }}</th>
              </tr>
              <tr>
                <th class="text-left">Totla Paid</th>
                <th class="text-right">{{ orderData.total_paid }}</th>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
    <b-card-code title="Refund">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Active -->
            <b-col md="12">
              <b-button variant="warning" @click="selectAll = !selectAll">{{
                selectAll ? "Un-Select All Products" : "Select All Products"
              }}</b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12">
              <b-card title="Products">
                <b-row>
                  <b-col
                    class="pt-1 pb-1 pr-5 pl-5"
                    cols="12"
                    v-for="(item, index) in sendData.variants"
                    :key="index"
                  >
                    <!-- Row Loop -->
                    <b-row>
                      <b-col class="pl-5" md="8">
                        <b-form-checkbox
                          :id="'checkbox' + index"
                          @change="checkArray"
                          v-model="item.selected"
                          :name="'checkbox' + index"
                          :value="true"
                          :unchecked-value="false"
                        >
                          <h4>{{ item.name }}</h4>
                        </b-form-checkbox>
                        <small style="padding-left: 10px;"
                          >Price: {{ item.price }}</small
                        >
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="Quantity that you want refund"
                          label-for="quantity"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="quantity"
                            rules="required|regex:^[0]*[1-9][0-9]*$"
                          >
                            <b-form-input
                              :id="'quantity' + index"
                              min="1"
                              :max="item.quantity"
                              type="number"
                              v-model="item.quantity"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-button
                  :disabled="disabledRefund"
                  @click="refund"
                  variant="danger"
                  >Refund</b-button
                >
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>

import BCardCode from "@core/components/b-card-code";
import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";

export default {
  components: {
    BCardCode,
  },  
  mixins: [heightTransition],
  data() {
    return {
      orderStatus: "",
      showDismissibleAlert: false,
      id: 0,
      errors_back: [],
      required,
      per: null,
      orderData: {},
      sendData: {
        order_id: 0,
        variants: [],
      },
      selectAll: false,
      disabledRefund: true,
    };
  },
  watch: {
    selectAll: function() {
      if (this.selectAll) {
        for (let index in this.sendData.variants) {
          this.sendData.variants[index].selected = true;
        }
        this.disabledRefund = false;
      } else {
        for (let index in this.sendData.variants) {
          this.sendData.variants[index].selected = false;
        }
        this.disabledRefund = true;
      }
    },
  },
  created() {
    // request status data
    this.sendData.order_id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("orders/" + this.$route.params.id)
        .then((result) => {
          this.orderData = result.data.data;
          //console.log(this.orderData);
          for (let index in this.orderData.variants) {
            this.sendData.variants.push({
              variant_id: this.orderData.variants[index].id,
              quantity: this.orderData.variants[index].quantity,
              selected: false,
              name: this.orderData.variants[index].name,
            });
          }
          this.orderStatus = this.orderData.orderstatus.name;
          if (this.orderData.is_refund == false) {
            this.$router.push("/orders/show/" +  this.$route.params.id);
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    checkArray() {
      //console.log(this.sendData.variants);

      if (this.sendData.variants.some((e) => e.selected === false)) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }

      if (
        this.sendData.variants.filter((e) => e.selected === true).length > 0
      ) {
        this.disabledRefund = false;
      } else {
        this.disabledRefund = true;
      }
    },
    refund() {
      var data = {
        order_id: this.sendData.order_id,
        variants: [],
      };
      for (let index in this.sendData.variants) {
        if (this.sendData.variants[index].selected == true) {
          data.variants.push({
            variant_id: this.sendData.variants[index].variant_id,
            quantity: this.sendData.variants[index].quantity,
          });
        }
      }
      axios
        .post("refunds", data)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.back(1);
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
